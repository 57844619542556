import * as React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TelegramIcon from '@material-ui/icons/Telegram';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';

import { useClient } from '../ClientProvider';
import useFlash from "../hooks/useFlash";
import {User} from "../types";

interface Row {
    guid: string;
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    last_login_at?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    search: {
        padding: theme.spacing(2),
    },
    searchInput: {
        minWidth: '300px',
    },
    table: {
        minWidth: 650,
    },
}));

const LicensesPage = (): JSX.Element => {
    const classes = useStyles({});
    const client = useClient();
    const [licenses, setLicenses] = React.useState([]);


    React.useEffect((): void => {
        client.getLicenses().then((response) => {
            setLicenses(response.data);
        });
    }, []);

    const [performingAction, setPerformingAction] = React.useState(false);
    const { success, error } = useFlash();

    const handleSendCredentials = (user: User) => (e): void => {
        e.preventDefault();

        setPerformingAction(true);
        client.sendCredentials(user).then((): void => {
            success('Identifiants envoyés avec succès.');
        }).catch((): void => {
            error('Une erreur est survenue.');
        }).finally((): void => {
            setPerformingAction(false);
        });
    };

    const handleLogAs = (user: User) => (e): void => {
        e.preventDefault();

        setPerformingAction(true);
        client.logAs(user).then((): void => {
            window.location.reload();
        }).catch((): void => {
            error('Une erreur est survenue.');
        }).finally((): void => {
            setPerformingAction(false);
        });
    };

    return (
        <div className={classes.root}>
            <List>
                {licenses.map((license) => (
                    <React.Fragment key={license.guid}>
                        <ListSubheader disableSticky>{license.company + " – " + license.license + " (" + license.plan + ")"}</ListSubheader>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Identifiant</TableCell>
                                    <TableCell>Nom</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Dernière connexion</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {license['admins'].map((user: Row) => (
                            <TableRow key={user.guid}>
                                <TableCell component="th" scope="row">
                                    {user.username}
                                </TableCell>
                                <TableCell>{`${user.firstname} ${user.lastname}`}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>
                                    {user.last_login_at && moment(user.last_login_at).calendar()}
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={`Se connecter en tant que ${user.username}`}>
                                        <IconButton
                                            edge="end"
                                            onClick={handleLogAs(user)}
                                            disabled={performingAction}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Envoyer les identifiants">
                                        <IconButton
                                            edge="end"
                                            onClick={handleSendCredentials(user)}
                                            disabled={performingAction}
                                        >
                                            <TelegramIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </React.Fragment>
                ))}
            </List>
        </div>
    );
};

export default LicensesPage;
