import * as React from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import { useSelector } from 'react-redux';
import { useClient } from '../ClientProvider';
import useStyles from '../hooks/useStyles';
import useForm from '../hooks/useForm';
import RouterLink from './RouterLink';
import PasswordInput from './PasswordInput';
import useActions from '../hooks/useActions';
import { Store } from '../types';
import useFlash from '../hooks/useFlash';

const Login = () => {
    const classes = useStyles({});
    const client = useClient();
    const history = useHistory();
    const actions = useActions();
    const username = useSelector((state: Store): string => state.username);
    const flash = useFlash();

    const submitHandler = ({ username, password }, reset): void => {
        client.auth(username, password).then(() => client.getCurrentUser()).then((response) => {
            actions.setUser(response.data);
            ReactGA.set({ userId: response.data.guid });
            history.push('/');
            reset();
        }).catch(() => {
            flash.error('Identifiants invalides.');
        });
    };

    const { handleSubmit, handleInputChange, inputs } = useForm(submitHandler, {
        username,
        password: '',
    });

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit} method="POST">
            <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Identifiant"
                name="username"
                autoFocus
                onChange={handleInputChange}
                value={inputs.username}
            />
            <PasswordInput
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                id="password"
                onChange={handleInputChange}
                value={inputs.password}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Se Connecter
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link component={RouterLink} to="/remind" variant="body2">
                        Mot de passe oublié ?
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
};

export default Login;
