import * as React from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/Lock';
import EditIcon from '@material-ui/icons/Edit';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

import PasswordInput from './PasswordInput';
import useForm from '../hooks/useForm';
import { useClient } from '../ClientProvider';
import useFlash from '../hooks/useFlash';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingTop: theme.spacing(2),
    },

    submit: {
        margin: theme.spacing(3, 0, 2),
    },

    cancel: {
        margin: theme.spacing(3, 0, 2),
        float: 'right',
    },

    input: {
        display: 'block',
    },
}));

const SecurityTab = (): JSX.Element => {
    const classes = useStyles({});

    const [showForm, setShowForm] = React.useState('');
    const [performingAction, setPerformingAction] = React.useState(false);
    const client = useClient();
    const { success, error } = useFlash();

    const changePassword = (inputs, reset) => {
        const { currentPassword, password } = inputs;

        setPerformingAction(true);
        client.updatePassword(currentPassword, password).then(() => {
            success('Mot de passe mis à jour avec succès.');
        }).catch((reason) => {
            error('Une erreur est survenue.');
            console.log(reason);
        }).finally(() => {
            setPerformingAction(false);
            setShowForm(false);
            reset();
        });
    };

    const { handleSubmit, handleInputChange, inputs } = useForm(changePassword, {
        currentPassword: '',
        password: '',
    });

    const handleCancel = (e) => {
        e.preventDefault();
        setShowForm(false);
    };

    return (
        <DialogContent classes={{ root: classes.dialogContent }}>

            { showForm && (
                <form noValidate onSubmit={handleSubmit} method="POST">
                    <PasswordInput
                        margin="normal"
                        autoComplete="current-password"
                        autoFocus
                        disabled={performingAction}
                        fullWidth
                        label="Mot de passe actuel"
                        required
                        value={inputs.currentPassword}
                        name="currentPassword"

                        className={classes.input}

                        onChange={handleInputChange}
                    />
                    <PasswordInput
                        margin="normal"
                        autoComplete="new-password"
                        autoFocus
                        disabled={performingAction}
                        fullWidth
                        label="Nouveau mot de passe"
                        required
                        value={inputs.password}
                        name="password"

                        className={classes.input}

                        onChange={handleInputChange}
                    />
                    <div>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Modifier
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.cancel}
                            onClick={handleCancel}
                        >
                            Annuler
                        </Button>
                    </div>
                </form>
            )}

            { !showForm && (
                <List disablePadding>
                    <ListItem>
                        <Hidden xsDown>
                            <ListItemIcon>
                                <LockIcon />
                            </ListItemIcon>
                        </Hidden>

                        <ListItemText
                            primary="Modifier votre mot de passe"
                        />

                        <ListItemSecondaryAction>
                            <Tooltip title="Change">
                                <div>
                                    <IconButton disabled={performingAction} onClick={() => setShowForm(true)}>
                                        <EditIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            )}
        </DialogContent>
    );
};

export default SecurityTab;
