import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { makeStyles, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import EyeIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

import Property from './Property';
import { useClient } from '../ClientProvider';
import FilesList from './FilesList';
import { File } from '../types';
import debounce from '../debounce';
import pluralize from '../pluralize';
import withConfirm from '../hooks/withConfirm';
import NewFileDialog from './NewFileDialog';
import { propertyTypes as types } from '../files';
import useFlash from "../hooks/useFlash";

interface Contract {
    guid: string;
    code: string;
    name: string;
    files: File[];
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    search: {
        padding: theme.spacing(2),
    },
    searchInput: {
        minWidth: '300px',
    },
}));

const byContract = (search: string) => (c: Contract): boolean => c.code.toLowerCase().indexOf(search) !== -1 || c.name.toLowerCase().indexOf(search) !== -1;

const PropertyPage = ({ confirm }): JSX.Element => {
    const classes = useStyles({});
    const { guid } = useParams();
    const client = useClient();
    const history = useHistory();
    const [property, setProperty] = React.useState(null);
    const [files, setFiles] = React.useState([]);
    const [contracts, setContracts] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const { success, error } = useFlash();

    React.useEffect(() => {
        client.getProperty(guid).then((response) => {
            setProperty(response.data);
        });
    }, []);

    React.useEffect(() => {
        if (!property) {
            return;
        }
        client.getPropertyFiles(property).then((response) => {
            setFiles(response.data);
        });

        client.getPropertyContracts(property).then((response) => {
            setContracts(response.data);
        });
    }, [property]);

    const handleSearch = debounce((value) => setSearch(value), 200, false);

    const goTo = (c: Contract) => (): void => {
        history.push(`/admin/contracts/${c.guid}`);
    };

    const handleDeleteFile = (file: File) => () => {
        client.deleteFile(file).then(() => client.getPropertyFiles(property)).then((response) => {
            setFiles(response.data);
        });
    };

    const [openSettings, setOpenSettings] = React.useState(false);

    const onFileSubmit = (file, type, name) => {
        client.uploadPropertyFile(property, file, type, name).then(() => {
            success('Fichier ajouté avec succès.');
            client.getPropertyFiles(property).then((response) => {
                setFiles(response.data);
            });
        }).catch(() => {
            error('Une erreur est survenue durant l\'ajout du fichier.');
        });
    };

    return (
        <div>
            {property && (
                <Property property={property} dense={false}>
                    <Button
                        onClick={(): void => setOpenSettings(true)}
                        variant="contained"
                        color="primary"
                    >
                        Ajouter
                    </Button>
                    <FilesList
                        group
                        files={files}
                        filter={(f: File): boolean => true}
                        actions={(file: File): JSX.Element => (
                            <Tooltip title="Supprimer le fichier">
                                <IconButton edge="end" aria-label="comments" onClick={confirm(handleDeleteFile(file))}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                    <NewFileDialog
                        open={openSettings}
                        onClose={(): void => setOpenSettings(false)}
                        onSubmit={onFileSubmit}
                        types={types}
                    />

                    <Typography component="p" variant="body1">
                        Contrats
                    </Typography>

                    <div className={classes.search}>
                        <TextField
                            id="input-with-icon-textfield"
                            placeholder="Rechercher"
                            className={classes.searchInput}
                            onChange={(e) => handleSearch(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <List>
                        {contracts.filter(byContract(search.toLowerCase()))
                            .map((c: Contract, i: number, cs: Contract[]) => (
                                <React.Fragment key={c.guid}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary={`${c.code} – ${c.name}`}
                                            secondary={pluralize(c.files.length, 'fichier')}
                                        />
                                        <ListItemSecondaryAction>
                                            <Tooltip title="Voir le détail">
                                                <IconButton edge="end" aria-label="comments" onClick={goTo(c)}>
                                                    <EyeIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {i !== cs.length - 1 && <Divider />}
                                </React.Fragment>
                            ))}
                    </List>
                </Property>
            )}
        </div>
    );
};

export default withConfirm(PropertyPage);
