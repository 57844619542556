import * as React from 'react';
import { useSelector } from 'react-redux';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';

import useStyles from '../hooks/useStyles';
import Copyright from './Copyright';
import logo from '../images/bluewhite-cercle.svg';
import useFlash from '../hooks/useFlash';
import SnackbarContentWrapper from './SnackbarContentWrapper';
import { FlashContent, Store } from '../types';

const PublicContainer = (props: React.PropsWithChildren<{}>): JSX.Element => {
    const classes = useStyles({});
    const flash = useSelector((state: Store): FlashContent => state.flash);
    const { close } = useFlash();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        close();
    };

    // https://www.cozimmo.com/reset-password/client/ce47104cd54fa64dae9c9f6bc089230fbf5783eb2986705f883bbe83adcf1633

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <img src={logo} alt="logo" className={classes.logo} />
                {props.children}
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={!!flash.text}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <SnackbarContentWrapper
                        onClose={handleClose}
                        variant={flash.variant}
                        message={flash.text}
                    />
                </Snackbar>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
};

export default PublicContainer;
