import * as React from 'react';
import { useSelector } from 'react-redux';

import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import useForm from '../hooks/useForm';
import { useClient } from '../ClientProvider';
import { Account, Store, User } from '../types';
import PasswordInput from './PasswordInput';
import useFlash from '../hooks/useFlash';
import useActions from '../hooks/useActions';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingTop: theme.spacing(2),
    },

    submit: {
        margin: theme.spacing(3, 0, 2),
    },

    cancel: {
        margin: theme.spacing(3, 0, 2),
        float: 'right',
    },

    empty: {
        margin: theme.spacing(2, 0),
    },
}));

const LinkedAccountTab = (): JSX.Element => {
    const classes = useStyles({});

    const user = useSelector((state: Store): User => state.me);
    const [performingAction, setPerformingAction] = React.useState(false);
    const [showForm, setShowForm] = React.useState(false);
    const client = useClient();
    const { error } = useFlash();
    const actions = useActions();

    const submitHandler = (inputs, reset) => {
        setPerformingAction(true);
        client.linkAccount(inputs.username, inputs.password).then(() => client.getCurrentUser()).then((response) => {
            actions.setUser(response.data);
        }).catch((reason) => {
            error('Une erreur est survenue.');
            console.log(reason);
        }).finally(() => {
            setPerformingAction(false);
            setShowForm(false);
            reset();
        });
    };

    const { handleSubmit, handleInputChange, inputs } = useForm(submitHandler, {
        username: '',
        password: '',
    });

    const accounts = user.linkedAccounts;

    const handleUnlink = (guid: string) => () => {
        setPerformingAction(true);
        client.unlinkAccount(guid).then(() => client.getCurrentUser()).then((response) => {
            actions.setUser(response.data);
        }).finally(() => {
            setPerformingAction(false);
        });
    };

    const handleCancel = (e) => {
        e.preventDefault();
        setShowForm(false);
    };

    return (
        <DialogContent classes={{ root: classes.dialogContent }}>
            { showForm && (
                <form noValidate onSubmit={handleSubmit} method="POST">
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Identifiant"
                        name="username"
                        autoFocus
                        onChange={handleInputChange}
                        value={inputs.username}
                    />
                    <PasswordInput
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Mot de passe"
                        id="password"
                        onChange={handleInputChange}
                        value={inputs.password}
                    />
                    <div>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Lier
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.cancel}
                            onClick={handleCancel}
                        >
                            Annuler
                        </Button>
                    </div>
                </form>
            )}

            { !showForm && (
                <>
                    <Button color="primary" variant="contained" onClick={() => setShowForm(true)}>
                        Lier un compte
                    </Button>
                    { accounts.length > 0 && (
                        <List disablePadding>
                            {accounts.map((account: Account) => (
                                <ListItem key={account.guid}>
                                    <ListItemText
                                        primary={`${account.firstname} ${account.lastname}`}
                                        secondary={account.username}
                                    />

                                    <ListItemSecondaryAction>
                                        <Tooltip title="Change">
                                            <div>
                                                <IconButton
                                                    disabled={performingAction}
                                                    onClick={handleUnlink(account.guid)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    )}
                    { accounts.length === 0 && (
                        <Typography component="p" variant="body2" className={classes.empty}>
                        Vous n'avez aucun compte lié pour le moment.
                        </Typography>
                    )}
                </>
            )}
        </DialogContent>
    );
};

export default LinkedAccountTab;
