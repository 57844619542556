import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Login from './components/Login';
import Remind from './components/Remind';
import Reset from './components/Reset';
import PublicContainer from './components/PublicContainer';
import Main from './components/Main';
import { useClient } from './ClientProvider';
import useActions from './hooks/useActions';
import DocumentsPage from './components/DocumentsPage';
import { Store, User } from './types';
import CompanyAdminSidebar from './components/CompanyAdminSidebar';
import ManagedUsersPage from './components/ManagedUsersPage';
import ImportsPage from './components/ImportsPage';
import ContractPage from './components/ContractPage';
import PropertyPage from './components/PropertyPage';
import PropertiesPage from './components/PropertiesPage';
import LicensesPage from "./components/LicensesPage";
import useTheme from './hooks/useTheme';

const App = (): JSX.Element => {
    const user = useSelector((state: Store): User => state.me);
    const [loading, setLoading] = React.useState(true);
    const client = useClient();
    const actions = useActions();
    const theme = useTheme();

    const muiTheme = createMuiTheme({
        palette: {
            primary: {
                main: theme.primaryColor,
                contrastText: '#FFFFFF',
            },
        },
    });

    React.useEffect(() => {
        if (!client.isAuthenticated()) {
            setLoading(false);
            return;
        }
        client.getCurrentUser().then((response) => {
            actions.setUser(response.data);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <div>
               Loading...
            </div>
        );
    }

    return (
        <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <Router>
                <Switch>
                    <Route path="/login">
                        <PublicContainer>
                            <Login />
                        </PublicContainer>
                    </Route>
                    <Route path="/remind">
                        <PublicContainer>
                            <Remind />
                        </PublicContainer>
                    </Route>
                    <Route path="/reset-password/:username/:token">
                        <PublicContainer>
                            <Reset />
                        </PublicContainer>
                    </Route>
                    <PrivateRoute exact path="/" restrict="">
                        { (user.role === 'user' || user.role === 'syndic') && (
                            <Main>
                                <DocumentsPage />
                            </Main>
                        )}
                        { user.role === 'company-admin' && (
                            <Redirect to={{ pathname: '/admin/imports' }} />
                        )}
                        { user.role === 'admin' && (
                            <Redirect to={{ pathname: '/admin/licenses' }} />
                        )}
                    </PrivateRoute>
                    <PrivateRoute path="/admin/imports" restrict="company-admin">
                        <Main sidebar={<CompanyAdminSidebar selected="/admin/imports" />}>
                            <ImportsPage />
                        </Main>
                    </PrivateRoute>
                    <PrivateRoute path="/admin/users" restrict="company-admin">
                        <Main sidebar={<CompanyAdminSidebar selected="/admin/users" />}>
                            <ManagedUsersPage />
                        </Main>
                    </PrivateRoute>
                    <PrivateRoute path="/admin/properties" exact restrict="company-admin">
                        <Main sidebar={<CompanyAdminSidebar selected="/admin/properties" />}>
                            <PropertiesPage />
                        </Main>
                    </PrivateRoute>
                    <PrivateRoute path="/admin/properties/:guid" restrict="company-admin">
                        <Main sidebar={<CompanyAdminSidebar selected="/admin/properties" />}>
                            <PropertyPage />
                        </Main>
                    </PrivateRoute>
                    <PrivateRoute path="/admin/contracts/:guid" restrict="company-admin">
                        <Main sidebar={<CompanyAdminSidebar selected="/admin/properties" />}>
                            <ContractPage />
                        </Main>
                    </PrivateRoute>
                    <PrivateRoute path="/admin/licenses" restrict="admin">
                        <Main>
                            <LicensesPage />
                        </Main>
                    </PrivateRoute>
                </Switch>
            </Router>
        </ThemeProvider>
    );
};

const PrivateRoute = ({ children, restrict, ...rest }) => {
    const client = useClient();
    const user = useSelector((state: Store): User => state.me);

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (client.isAuthenticated()) {
                    if (restrict && restrict !== user.role) {
                        return <Redirect to={{ pathname: '/', state: { from: location } }} />;
                    }
                    return children;
                }

                return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
            }}
        />
    );
};

export default App;
