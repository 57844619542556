import * as React from 'react';
import Resumablejs from 'resumablejs';

import Button from '@material-ui/core/Button';
import { ListItem, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import FileIcon from '@material-ui/icons/Description';
import CancelIcon from '@material-ui/icons/Clear';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { useClient } from '../ClientProvider';
import ResumableFile = Resumable.ResumableFile;

const useStyles = makeStyles((theme) => ({
    list: {
        maxWidth: '600px',
    },
    upload: {
        margin: theme.spacing(2),
    },
    icon: {
        minWidth: theme.spacing(4),
    },
    item: {
        paddingRight: theme.spacing(2),
    },
    cancel: {
        height: '0.8em',
        width: '0.8em',
    },
}));

const DropZone = (): JSX.Element => {
    const classes = useStyles({});
    const client = useClient();
    const ref = React.useRef();
    const [files, setFiles] = React.useState({});
    const [successes, setSuccesses] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [cancelled, setCancelled] = React.useState({});

    React.useEffect((): void => {
        const field = new Resumablejs({
            maxFiles: undefined,
            target: client.getImportURL(),
            headers: {
                // TODO(kevin.lebrun): handle refresh auth
                Authorization: client.getAuthorizationHeader(),
            },
            fileType: [
                'zip',
            ],
            simultaneousUploads: 3,
            chunkSize: 1024 * 1024 * 2,
            withCredentials: true,
            // FIXME
            allowDuplicateUploads: true,
            chunkNumberParameterName: 'flowChunkNumber',
            chunkSizeParameterName: 'flowChunkSize',
            currentChunkSizeParameterName: 'flowCurrentChunkSize',
            totalSizeParameterName: 'flowTotalSize',
            typeParameterName: 'flowType',
            identifierParameterName: 'flowIdentifier',
            fileNameParameterName: 'flowFilename',
            relativePathParameterName: 'flowRelativePath',
            totalChunksParameterName: 'flowTotalChunks',
        });

        field.on('fileAdded', (file, event) => {
            setFiles({ ...files, [file.uniqueIdentifier]: file });
            field.upload();
        });

        field.on('fileError', (file, message) => {
            setFiles({ ...files, [file.uniqueIdentifier]: file });
            setErrors({ ...errors, [file.uniqueIdentifier]: message });
        });

        field.on('fileProgress', (file) => {
            setFiles({ ...files, [file.uniqueIdentifier]: file });
        });

        field.on('fileSuccess', (file) => {
            setFiles({ ...files, [file.uniqueIdentifier]: file });
            setSuccesses({ ...successes, [file.uniqueIdentifier]: file.fileName });
        });

        field.assignBrowse(ref.current, false);
    }, []);

    const handleCancel = (file: ResumableFile) => () => {
        setCancelled({ ...cancelled, [file.uniqueIdentifier]: file });
        file.cancel();
    };

    return (
        <div>
            <Button color="primary" variant="contained" ref={ref} className={classes.upload}>Importer</Button>

            <List dense className={classes.list}>
                {Object.keys(files).filter((key: string): boolean => !cancelled[key]).map((key: string): JSX.Element => (
                    <ListItem classes={{ root: classes.item }}>
                        <ListItemIcon className={classes.icon}>
                            <FileIcon />
                        </ListItemIcon>

                        {errors[key] && (
                            <ListItemText
                                primary={`erreur durant l'import de ${files[key].fileName}`}
                            />
                        )}

                        {!errors[key] && !successes[key] && (
                            <>
                                <ListItemText
                                    primary={files[key].fileName}
                                    secondary={(
                                        <LinearProgress variant="determinate" value={files[key].progress() * 100} />
                                    )}
                                />

                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="cancel" onClick={handleCancel(files[key])}>
                                        <CancelIcon className={classes.cancel} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </>
                        )}
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default DropZone;
