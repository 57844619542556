import * as React from 'react';

import { makeStyles, Table, Typography } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import { useSelector } from 'react-redux';
import DropZone from './DropZone';
import { Store, User, File } from '../types';
import { useClient } from '../ClientProvider';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";
import {bytes} from "../filters";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";

interface Row {
    guid: string;
    createdAt: string;
    updatedAt: string;
    status: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
}));

const formatStatus = (status: string): string => {
    switch (status) {
    case 'success':
        return 'Succès';
    case 'failure':
    case 'error':
        return 'Erreur';
    case 'pending':
        return 'En cours';
    default:
    }
    return 'Inconnu';
};

const ImportsPage = () => {
    const classes = useStyles({});
    const client = useClient();
    const me = useSelector((state: Store): User => state.me);
    const [imports, setImports] = React.useState([]);

    React.useEffect(() => {
        const refresh = () => {
            client.getImports(me).then((response) => {
                const rows: Row[] = [];
                // TODO refresh frequently
                response.data.forEach((data: {}): void => {
                    rows.push({
                        guid: data.guid,
                        createdAt: data.created_at,
                        updatedAt: data.updated_at,
                        status: data.status,
                    });
                });
                setImports(rows);
            });
        };

        refresh();

        const interval = setInterval(refresh, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);


    const handleDownload = (guid) => {
        return (e): void => {
            client.getImportDownloadURL(guid)
                .then((response): void => {
                    if (response.data.url) {
                        // eslint-disable-next-line no-restricted-globals
                        location.href = response.data.url;
                    }
                });
            e.preventDefault();
        }
    };

    return (
        <div className={classes.root}>
            <DropZone />

            <Typography component="h2" variant="h6">
                Historique des imports
            </Typography>

            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Date de début</TableCell>
                        <TableCell>Dernière mise à jour</TableCell>
                        <TableCell>État</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {imports.slice(0, 15).map((row: Row) => (
                        <TableRow key={row.guid}>
                            <TableCell><a href="#" onClick={handleDownload(row.guid)}>{row.guid}</a></TableCell>
                            <TableCell>{moment(row.createdAt).format('LLLL')}</TableCell>
                            <TableCell>{moment(row.updatedAt).format('LLLL')}</TableCell>
                            <TableCell>{formatStatus(row.status)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default ImportsPage;
