import * as React from 'react';

const nl2br = (text: string): React.ReactNode => text.split('\n').map((item, key) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={key}>
        {item}
        <br />
    </React.Fragment>
));

export default nl2br;
