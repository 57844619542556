import { Store } from './types';
import { initialState } from './store';

export default function reducer(previousState: Store, action: any): Store {
    if (action.type === 'logout') {
        return initialState;
    }

    if (action.type === 'token') {
        return { ...previousState, token: action.token };
    }

    if (action.type === 'user') {
        return {
            ...previousState,
            me: {
                guid: action.data.guid,
                role: action.data.role,
                username: action.data.username,
                firstname: action.data.firstname,
                lastname: action.data.lastname,
                email: action.data.email,
                loginHistory: action.data.login_history,
                updateNotifications: action.data.update_notifications,
                linkedAccounts: action.data.linked_users,
                license: action.data.license,
                scope: action.data.scope,
            },
        };
    }

    if (action.type === 'flash') {
        return { ...previousState, flash: action.data };
    }

    if (action.type === 'username') {
        return { ...previousState, username: action.username };
    }

    return previousState;
}
