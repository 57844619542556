import * as React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import useStyles from '../hooks/useStyles';
import useForm from '../hooks/useForm';
import { useClient } from '../ClientProvider';
import useFlash from '../hooks/useFlash';

interface Props {
    open: boolean;
    onClose: () => void;
}

const FeedbackDialog = (props: Props): JSX.Element => {
    const classes = useStyles({});
    const { open, onClose } = props;
    const client = useClient();
    const flash = useFlash();

    const submitHandler = ({ reason, message }, reset): void => {
        client.postFeedback({
            reason,
            message,
            userAgent: navigator.userAgent,
        }).then(() => {
            flash.success('Votre message a été envoyé.');
            reset();
            onClose();
        }).catch(() => {
            flash.error('Une erreur est survenue. Merci de réessayer.');
        });
    };

    const { handleSubmit, handleInputChange, inputs } = useForm(submitHandler, {
        reason: 'idea',
        message: '',
    });

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle disableTypography>
                <Typography variant="h6">
                    Contactez-nous
                </Typography>

                <Tooltip title="Close">
                    <IconButton className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Laissez-nous un message. Nous vous répondrons dès que possible.
                </DialogContentText>
                <FormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">Objet</InputLabel>
                    <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={inputs.reason}
                        onChange={handleInputChange}
                        name="reason"
                    >
                        <MenuItem value="technical_issue">Je rencontre un problème technique.</MenuItem>
                        <MenuItem value="about_data">J'ai une question à propos de mes données.</MenuItem>
                        <MenuItem value="idea">J'ai une idée ou une suggestion.</MenuItem>
                        <MenuItem value="other">Je ne suis pas sûr(e).</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    autoFocus
                    multiline
                    rows={6}
                    rowsMax={12}
                    margin="dense"
                    id="message"
                    label="Message"
                    fullWidth
                    variant="filled"
                    name="message"
                    value={inputs.message}
                    onChange={handleInputChange}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Envoyer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FeedbackDialog;
