import * as React from 'react';

import { List, makeStyles, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/core/SvgIcon/SvgIcon';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { Contract as ContractType } from '../types';
import nl2br from './nl2br';

interface Props {
    contract: ContractType;
    dense: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
    },
    title: {
        padding: theme.spacing(1, 0, 0, 2),
    },
    panel: {
        margin: theme.spacing(0, 2, 4, 2),
    },
    files: {
        margin: theme.spacing(0, 2),
    },
}));

const findPropertyProportionBase = (contract: ContractType, code: string): string => {
    const { proportions } = contract.property;
    for (const p of proportions) {
        if (p.code === code) {
            return p.base;
        }
    }
    return '';
};

const Contract = (props: React.PropsWithChildren<Props>): JSX.Element => {
    const classes = useStyles({});
    const { contract, dense, children } = props;

    return (
        <div className={classes.root}>
            <Typography component="h2" variant="h6" className={classes.title}>
                Contrat&nbsp;:&nbsp;
                {contract.code}
            </Typography>
            {!dense && (
                <>
                    <List>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary="Copropriété"
                                secondary={`${contract.property.code} ${contract.property.label}`}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary="Contact"
                                secondary={(
                                    <>
                                        {`${contract.title} ${contract.name}`}
                                        <br />
                                        {nl2br(contract.address.address)}
                                    </>
                                )}
                            />
                        </ListItem>
                    </List>
                    <div className={classes.panel}>
                        { contract.properties_shares.map((share) => (
                            <ExpansionPanel key={share.code}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        Lot&nbsp;:&nbsp;
                                        {share.code}
                                        {' '}
                                        {share.label}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Clé</TableCell>
                                                <TableCell align="left">Désignation</TableCell>
                                                <TableCell align="right">Tantièmes</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {share.proportions.map((row) => (
                                                <TableRow key={row.code}>
                                                    <TableCell component="th" scope="row">
                                                        {row.code}
                                                    </TableCell>
                                                    <TableCell align="left">{row.label}</TableCell>
                                                    <TableCell align="right">
                                                        {`${row.proportion} / ${findPropertyProportionBase(contract, row.code)}`}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))}
                    </div>
                </>
            )}

            <div className={classes.files}>
                {!dense && (
                    <Typography component="p" variant="body1">
                        Documents personnels
                    </Typography>
                )}

                {children}
            </div>
        </div>
    );
};

export default Contract;
