import * as React from 'react';

import { InputAdornment, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { RemoveRedEye } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    eye: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
    },
}));

const PasswordInput = (props): JSX.Element => {
    const classes = useStyles({});
    const [masked, setMasked] = React.useState(true);

    const togglePasswordMask = () => {
        setMasked(!masked);
    };

    return (
        <TextField
            type={masked ? 'password' : 'text'}
            {...props}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <RemoveRedEye
                            className={classes.eye}
                            onClick={togglePasswordMask}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PasswordInput;
