const units = ['o', 'Kio', 'Mio', 'Gio', 'Tio', 'Pio'];

export const bytes = (b: number): string => {
    if (b === 0) {
        return '0';
    }

    let unit = 0;

    while (b >= 1024) {
        b /= 1024;
        unit++;
    }

    return `${Math.round(b * 100) / 100} ${units[unit]}`;
};
