import * as React from 'react';

const useForm = (callback, defaultInputs = {}) => {
    const [inputs, setInputs] = React.useState(defaultInputs);

    const reset = (): void => {
        setInputs(defaultInputs);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        if (event) {
            event.preventDefault();
        }
        callback(inputs, reset);
    };

    const handleInputChange = (event): void => {
        event.persist();
        setInputs((xs) => ({ ...xs, [event.target.name]: event.target.value }));
    };

    const set = (key: string, value: string): void => {
        setInputs((xs) => ({ ...xs, [key]: value }));
    };

    return { handleSubmit, handleInputChange, inputs, set };
};

export default useForm;
