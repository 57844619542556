import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import moment from 'moment';
import 'moment/locale/fr';

import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';

import App from './App';
import ClientProvider from './ClientProvider';
import { initialState } from './store';
import rootReducer from './reducers';

moment.locale('fr');

let store = createStore(rootReducer, initialState);
let debug = false;

if (process.env.NODE_ENV === 'development') {
    /* eslint-disable no-underscore-dangle */
    store = createStore(rootReducer, initialState,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    /* eslint-enable */
    debug = true;
}

ReactGA.initialize('UA-110460335-1', { debug });
Sentry.init({ dsn: 'https://ed13df0d42144fd2b643b95c1f51fbd9@sentry.io/1219206' });

const history = createBrowserHistory();

history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

const defaultBaseURL = 'https://api.cozimmo.com/main';
// const defaultBaseURL = 'http://localhost:8000/main';

const Root = (
    <Provider store={store}>
        <ClientProvider baseURL={process.env.BASE_URL || defaultBaseURL}>
            <App />
        </ClientProvider>
    </Provider>
);

ReactDOM.render(Root, document.getElementById('app'));
