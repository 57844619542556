import * as React from 'react';
import { useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LinkIcon from '@material-ui/icons/Link';
import SecurityIcon from '@material-ui/icons/Security';

import AccountTab from './AccountTab';
import useStyles from '../hooks/useStyles';
import SecurityTab from './SecurityTab';
import LinkedAccountTab from './LinkedAccountTab';
import { Store, User } from '../types';

const tabs = [
    {
        key: 'account',
        className: 'account-tab',
        icon: <AccountCircleIcon />,
        label: 'Compte',
        component: <AccountTab />,
        restrict: null,
    },

    {
        key: 'security',
        className: 'security-tab',
        icon: <SecurityIcon />,
        label: 'Sécurité',
        component: <SecurityTab />,
        restrict: null,
    },

    {
        key: 'links',
        className: 'links-tab',
        icon: <LinkIcon />,
        label: 'Comptes liés',
        component: <LinkedAccountTab />,
        restrict: 'user',
    },
];

interface Props {
    open: boolean;
    tabIndex: number;
    onClose: () => void;
}

const SettingsDialog = (props: Props): JSX.Element => {
    const classes = useStyles({});
    const { open, tabIndex, onClose } = props;

    const [selectedTab, selectTab] = React.useState(tabIndex);
    const user = useSelector((state: Store): User => state.me);

    const handleTabChange = (event, value): void => {
        selectTab(value);
    };

    React.useEffect(() => {
        if (tabIndex !== selectedTab) {
            selectTab(tabIndex);
        }
    }, [tabIndex]);

    const restrict = (tab): boolean => tab.restrict === null || tab.restrict === user.role;

    return (
        <Dialog open={open} onClose={onClose} classes={{ paper: classes.settingsDialog }}>
            <DialogTitle disableTypography>
                <Typography variant="h6">
                    Paramètres
                </Typography>

                <Tooltip title="Close">
                    <IconButton className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </DialogTitle>

            <Tabs
                classes={{ root: classes.tabs }}
                style={{ overflow: 'initial', minHeight: 'initial' }}

                indicatorColor="primary"
                textColor="primary"
                value={selectedTab}
                variant="fullWidth"
                onChange={handleTabChange}
            >
                {tabs.filter(restrict).map((tab) => (
                    <Tab key={tab.key} icon={tab.icon} label={tab.label} className={tab.className} />
                ))}
            </Tabs>

            <div>
                {tabs[selectedTab].component}
            </div>
        </Dialog>
    );
};

export default SettingsDialog;
