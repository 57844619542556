import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Drawer, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PublishIcon from '@material-ui/icons/Publish';
import PeopleIcon from '@material-ui/icons/People';
import FileIcon from '@material-ui/icons/Description';
import ListItemText from '@material-ui/core/ListItemText';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },

    toolbar: theme.mixins.toolbar,
}));

const links = [
    {
        title: 'Imports',
        icon: <PublishIcon />,
        path: '/admin/imports',
    },
    {
        title: 'Utilisateurs',
        icon: <PeopleIcon />,
        path: '/admin/users',
    },
    {
        title: 'Copropriétés',
        icon: <FileIcon />,
        path: '/admin/properties',
    },
];

interface Props {
    selected: string;
}

const CompanyAdminSidebar = (props: Props): JSX.Element => {
    const classes = useStyles({});
    const history = useHistory();
    const { selected } = props;

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.toolbar} />
            <List>
                {links.map((link) => (
                    <ListItem
                        button
                        key={link.title}
                        onClick={(): void => history.push(link.path)}
                        selected={link.path === selected}
                    >
                        <ListItemIcon>{link.icon}</ListItemIcon>
                        <ListItemText primary={link.title} />
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};

export default CompanyAdminSidebar;
