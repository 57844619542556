// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import moment from 'moment';

import { File } from './types';

interface PresentableFile {
    size: number;
    name: string;
    primary: string;
    secondary: string;
}

const presentFile = (file: File): PresentableFile => {
    const presentable: PresentableFile = {
        size: file.size,
        name: file.name,
        primary: file.metadata.label || file.name,
        secondary: '',
    };

    switch (file.type) {
    case 'facture':
        let date = `du ${moment(file.metadata.start).format('Do MMMM YYYY')} au ${moment(file.metadata.end).format('Do MMMM YYYY')}`;
        if (file.metadata.start === file.metadata.end) {
            date = `le ${moment(file.metadata.start).format('Do MMMM YYYY')}`;
        }
        presentable.secondary = `${date}`;
        break;

    case 'ag':
        presentable.primary = file.metadata.label || 'Assemblée générale';
        const date = `le ${moment(file.metadata.date).format('Do MMMM YYYY')}`;
        presentable.secondary = `${date}`;
        break;

    case 'appel_fonds':
        presentable.primary = file.metadata.label || `Appel de fonds ${file.metadata.code || ''}`;
        const date = `du ${moment(file.metadata.start).format('Do MMMM YYYY')} au ${moment(file.metadata.end).format('Do MMMM YYYY')}`;
        presentable.secondary = `${date}`;
        break;

    case 'sru':
        presentable.primary = file.metadata.label || 'Annexes SRU';
        const date = `en ${moment(file.metadata.start).format('YYYY')}`;
        presentable.secondary = `${date}`;
        break;

    case 'extrait_compte':
        presentable.primary = file.metadata.label || 'Extrait de compte';
        const date = `du ${moment(file.metadata.start).format('Do MMMM YYYY')} au ${moment(file.metadata.end).format('Do MMMM YYYY')}`;
        presentable.secondary = `${date}`;
        break;

    case 'reglement_copro':
        presentable.primary = file.metadata.label || 'Règlement de copropriété';
        const date = `le ${moment(file.metadata.date).format('Do MMMM YYYY')}`;
        presentable.secondary = `${date}`;
        break;

    default:
        break;
    }

    return presentable;
};

export default presentFile;
