import * as React from 'react';
import { useHistory } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EyeIcon from '@material-ui/icons/Visibility';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import { useClient } from '../ClientProvider';
import debounce from '../debounce';
import pluralize from '../pluralize';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    search: {
        padding: theme.spacing(2),
    },
    searchInput: {
        minWidth: '300px',
    },
}));

interface Property {
    guid: string;
    agent: string;
    code: string;
    label: string;
    contracts: number;
    files: number;
}

const groupBy = (properties: Property[]): { [key: string]: Property[] } => (
    properties.reduce((acc, property: Property) => {
        const key = property.agent;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(property);
        return acc;
    }, {})
);

const PropertiesPage = (): JSX.Element => {
    const classes = useStyles({});
    const client = useClient();
    const history = useHistory();
    const [properties, setProperties] = React.useState([]);
    const [search, setSearch] = React.useState('');

    React.useEffect((): void => {
        client.getProperties().then((response) => {
            setProperties(response.data);
        });
    }, []);

    // eslint-disable-next-line max-len
    const grouped = groupBy(properties.filter((p: Property): boolean => p.code.toLowerCase().indexOf(search.toLowerCase()) !== -1
            || p.label.toLowerCase().indexOf(search.toLowerCase()) !== -1));

    const goTo = (p: Property) => (): void => {
        history.push(`/admin/properties/${p.guid}`);
    };

    const handleSearch = debounce((value) => setSearch(value), 200, false);

    return (
        <div className={classes.root}>
            <div className={classes.search}>
                <TextField
                    id="input-with-icon-textfield"
                    placeholder="Rechercher"
                    className={classes.searchInput}
                    onChange={(e) => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="primary" />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <List>
                {Object.keys(grouped).map((key: string) => (
                    <React.Fragment key={key}>
                        <ListSubheader disableSticky>{key}</ListSubheader>
                        {grouped[key].map((p: Property, i: number, ps: Property[]) => (
                            <React.Fragment key={p.guid}>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary={`${p.code} – ${p.label}`}
                                        secondary={`${pluralize(p.files, 'fichier')} – ${pluralize(p.contracts, 'contrat')}`}
                                    />
                                    <ListItemSecondaryAction>
                                        <Tooltip title="Voir le détail">
                                            <IconButton edge="end" aria-label="comments" onClick={goTo(p)}>
                                                <EyeIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {i !== ps.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </List>
        </div>
    );
};

export default PropertiesPage;
