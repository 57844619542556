import axios, { AxiosInstance } from 'axios';

import {
    File, User, Property, Contract,
} from './types';

interface Feedback {
    reason: string;
    message: string;
    userAgent: string;
}

// TODO the user should not assume the API response payload
class Client {
    private client: AxiosInstance;

    private baseURL: string;

    private token: string;

    public constructor(baseURL: string) {
        this.baseURL = baseURL;
        this.client = axios.create({ baseURL });
        this.token = window.sessionStorage.getItem('access_token') || '';
        if (this.token.length > 0) {
            axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        }
    }

    getImportURL(): string {
        return `${this.baseURL}/v1/import`;
    }

    getAuthorizationHeader(): string {
        return `Bearer ${this.token}`;
    }

    public isAuthenticated(): boolean {
        return this.token !== '';
    }

    auth(username: string, password: string) {
        return this.client.post('/v1/oauth/token', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            grant_type: 'password',
            username,
            password,
        }).then((response) => {
            axios.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
            window.sessionStorage.setItem('access_token', response.data.access_token);
            this.token = response.data.access_token;
            return response;
        });
    }

    remind(username: string) {
        return this.client.post('/v1/password/remind', {
            username,
        });
    }

    reset(username: string, token: string, password: string, passwordConfirmation: string) {
        return this.client.post('/v1/password/reset', {
            username,
            token,
            password,
            // eslint-disable-next-line @typescript-eslint/camelcase
            password_confirmation: passwordConfirmation,
        });
    }

    getCurrentUser() {
        return this.client.get('/v1/me');
    }

    getFiles() {
        return this.client.get('/v1/me/files');
    }

    getProperties() {
        return this.client.get('/v1/me/properties');
    }

    getContracts() {
        return this.client.get('/v1/me/contracts');
    }

    getDownloadURL(file: File) {
        return this.client.get(`/v1/files/${file.guid}/download-url`);
    }

    getImportDownloadURL(guid: string) {
        return this.client.get(`/v1/imports/${guid}/download-url`);
    }

    getLicenses() {
        return this.client.get(`/v1/admin/licenses`);
    }

    updatePassword(currentPassword: string, password: string, passwordConfirmation: string) {
        return this.client.put('/v1/me/password', {
            oldpassword: currentPassword,
            newpassword: password,
            // eslint-disable-next-line @typescript-eslint/camelcase
            newpassword_confirmation: passwordConfirmation,
        });
    }

    updateUserInfo(user: User, email: string, updateNotifications: boolean) {
        return this.client.patch(`/v1/users/${user.guid}`, {
            email,
            // eslint-disable-next-line @typescript-eslint/camelcase
            update_notifications: updateNotifications,
        });
    }

    unlinkAccount(guid: string) {
        return this.client.delete(`/v1/linked-users/${guid}`);
    }

    linkAccount(username: string, password: string) {
        return this.client.post('/v1/linked-users', {
            username,
            password,
        });
    }

    postFeedback(feedback: Feedback) {
        return this.client.post('/v1/feedback', {
            reason: feedback.reason,
            message: feedback.message,
            user_agent: feedback.userAgent,
        });
    }

    getManagedUsers(user: User) {
        return this.client.get(`/v1/users/${user.guid}/managed-users`);
    }

    sendCredentials(user: User) {
        return this.client.post(`/v1/users/${user.guid}/resend-credentials`);
    }

    logAs(user: User) {
        return this.client.post(`/v1/users/${user.guid}/token`).then((response) => {
            const parentToken = window.sessionStorage.getItem('parent_access_token');
            // in case of nested sessions we keep the parent one
            if (!parentToken) {
                window.sessionStorage.setItem('parent_access_token', this.token);
            }

            axios.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
            window.sessionStorage.setItem('access_token', response.data.access_token);
            this.token = response.data.access_token;
            return response;
        });
    }

    getImports(user: User) {
        return this.client.get(`/v1/users/${user.guid}/imports`);
    }

    getProperty(guid: string) {
        return this.client.get(`/v1/properties/${guid}`);
    }

    getPropertyFiles(property: Property) {
        return this.client.get(`/v1/properties/${property.guid}/files`);
    }

    getPropertyContracts(property: Property) {
        return this.client.get(`/v1/properties/${property.guid}/contracts`);
    }

    getContract(guid: string) {
        return this.client.get(`/v1/contracts/${guid}`);
    }

    getContractFiles(contract: Contract) {
        return this.client.get(`/v1/contracts/${contract.guid}/files`);
    }

    deleteFile(file: File) {
        return this.client.delete(`/v1/files/${file.guid}`);
    }

    uploadContractFile(contract: Contract, file, type, name) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);
        formData.append('name', file.name);
        formData.append('metadata', JSON.stringify({ label: name }));
        return this.client.post(`/v1/contracts/${contract.guid}/files`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    uploadPropertyFile(property: Property, file, type, name) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);
        formData.append('name', file.name);
        formData.append('metadata', JSON.stringify({label: name}));
        return this.client.post(`/v1/properties/${property.guid}/files`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    clearToken(): Promise {
        return new Promise((resolve, reject) => {
            const parentToken = window.sessionStorage.getItem('parent_access_token');
            if (parentToken) {
                axios.defaults.headers.common.Authorization = `Bearer ${parentToken}`;
                window.sessionStorage.setItem('access_token', parentToken);
                this.token = parentToken;
                window.sessionStorage.removeItem('parent_access_token');

                resolve(true);
                return;
            }

            axios.defaults.headers.common.Authorization = null;
            window.sessionStorage.clear();
            this.token = '';
            resolve(false);
        });
    }
}

export default Client;
