import * as React from 'react';
import { useSelector } from 'react-redux';

import { Store, User } from '../types';

import defaultLogo from '../images/bluewhite-cercle.svg';
import primogestLogo from '../images/logo-primogest_white.png';

const defaultTheme = {
    primaryColor: '#80AFBF',
    logo: defaultLogo,
};

const themeForLicense = {
    HEXA0247_1: {
        primaryColor: '#A52825',
        logo: primogestLogo,
    },
};

interface Theme {
    primaryColor: string;
    logo: string;
}

const useTheme = (): Theme => {
    const [theme, setTheme] = React.useState<Theme>(defaultTheme);
    const me = useSelector((state: Store): User => state.me);

    React.useEffect(() => {
        setTheme(themeForLicense[me.license] || defaultTheme);
    }, [me]);

    return theme;
};

export default useTheme;
