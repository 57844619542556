import * as React from 'react';

import List from '@material-ui/core/List';
import Switch from '@material-ui/core/Switch';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import { Contract as ContractType, File, Property as PropertyType } from '../types';
import useStyles from '../hooks/useStyles';
import Property from './Property';
import Contract from './Contract';
import useCheckboxList from '../hooks/useCheckboxList';
import FilesList from './FilesList';
import { types as typesStr } from '../files';
import { useClient } from '../ClientProvider';
import debounce from '../debounce';

const byName = (name: string) => (file: File): boolean => file.name.toLowerCase().indexOf(name) !== -1;

const DocumentsPage = () => {
    const classes = useStyles({});
    const client = useClient();

    const [files, setFiles] = React.useState([]);
    const [properties, setProperties] = React.useState({});
    const [contracts, setContracts] = React.useState({});
    const [dependentContracts, setDependentContracts] = React.useState(contracts);
    const [types, setTypes] = React.useState({});

    const propertiesList = useCheckboxList(properties);
    const contractsList = useCheckboxList(dependentContracts);
    const typesList = useCheckboxList(types);

    React.useEffect(() => {
        Promise.all([
            client.getFiles(),
            client.getProperties(),
            client.getContracts(),
        ]).then(([{ data: rawFiles }, { data: rawProperties }, { data: rawContracts }]): void => {
            setFiles(rawFiles);

            const newProperties = {};
            const newContracts = {};

            rawProperties.forEach((property: PropertyType): void => {
                newProperties[property.guid] = property;
            });

            rawContracts.forEach((contract: ContractType): void => {
                newContracts[contract.guid] = contract;
                newProperties[contract.property.guid] = contract.property;
            });

            setContracts(newContracts);
            setDependentContracts(newContracts);
            contractsList.update(newContracts);
            contractsList.selectNone();
            setProperties(newProperties);
            propertiesList.update(newProperties);
            propertiesList.selectNone();
        });
    }, []);

    const [search, setSearch] = React.useState('');

    const [dense, setDense] = React.useState(false);
    const [groupFiles, setGroupFiles] = React.useState(true);

    const propertiesKeys = Object.keys(properties).filter((k: string): boolean => propertiesList.isSelected(k));
    const contractsKeys = Object.keys(dependentContracts).filter((k: string): boolean => contractsList.isSelected(k));

    const filesForProperty: { [key: string]: File[] } = {};
    const filesForContract: { [key: string]: File[] } = {};

    files.filter(byName(search.toLowerCase())).forEach((f: File): void => {
        if (f.property) {
            filesForProperty[f.property.guid] = filesForProperty[f.property.guid] || [];
            filesForProperty[f.property.guid].push(f);
        }

        if (f.contract) {
            filesForContract[f.contract.guid] = filesForContract[f.contract.guid] || [];
            filesForContract[f.contract.guid].push(f);
        }
    });

    React.useEffect(() => {
        // eslint-disable-next-line no-shadow
        const types: { [type: string]: boolean } = {};

        propertiesKeys.forEach((key: string): void => {
            if (!filesForProperty[key]) {
                return;
            }
            filesForProperty[key].forEach((f: File): void => {
                types[f.type] = true;
            });
        });

        contractsKeys.forEach((key: string): void => {
            if (!filesForContract[key]) {
                return;
            }
            filesForContract[key].forEach((f: File): void => {
                types[f.type] = true;
            });
        });

        setTypes(types);
        typesList.update(types);
    }, [propertiesList.current, contractsList.current, files]);

    React.useEffect(() => {
        let cs: { [key: string]: ContractType } = {};

        if (propertiesList.selectedCount() !== 0) {
            const keys = Object.keys(contracts).filter((k: string): boolean => {
                const { property } = contracts[k];
                return propertiesList.isSelected(property.guid);
            });

            keys.forEach((k: string): void => {
                cs[k] = contracts[k];
            });
        } else {
            cs = contracts;
        }

        setDependentContracts(cs);
        contractsList.update(cs);
        contractsList.selectNone();
    }, [propertiesList.current]);

    const selectOnlyContract = (key: string) => (e): void => {
        e.preventDefault();
        contractsList.selectOnly(key);
    };

    const selectOnlyProperty = (key: string) => (e): void => {
        e.preventDefault();
        propertiesList.selectOnly(key);
        contractsList.selectNone();
    };

    const handleSearch = debounce((value) => setSearch(value), 200, false);

    return (
        <div className={classes.root}>
            <Grid container spacing={2} className="page">
                <Grid item xs={3} className="filter-panel">
                    <div className={classes.sidebar}>
                        <Typography className={classes.titleWithAction}>
                            Options
                        </Typography>
                        <List dense>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary="Afficher les détails"
                                />
                                <ListItemSecondaryAction>
                                    <Switch
                                        edge="end"
                                        color="primary"
                                        onChange={(e) => setDense(!e.target.checked)}
                                        checked={!dense}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary="Grouper les documents"
                                />
                                <ListItemSecondaryAction>
                                    <Switch
                                        edge="end"
                                        color="primary"
                                        onChange={(e) => setGroupFiles(e.target.checked)}
                                        checked={groupFiles}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                        { Object.keys(types).length > 0 && (
                            <>
                                <Typography className={classes.titleWithAction}>
                                    Types
                                    { typesList.allSelected() && (
                                        <Button
                                            onClick={typesList.selectNone}
                                            color="primary"
                                            className={classes.inlineButton}
                                        >
                                            Cacher tout
                                        </Button>
                                    )}
                                    { !typesList.allSelected() && (
                                        <Button
                                            onClick={typesList.selectAll}
                                            color="primary"
                                            className={classes.inlineButton}
                                        >
                                            Voir tout
                                        </Button>
                                    )}
                                </Typography>
                                <List dense>
                                    { Object.keys(types).map((key: string) => (
                                        <ListItem alignItems="flex-start" key={key}>
                                            <ListItemText
                                                primary={(
                                                    <Link href="#" onClick={() => typesList.selectOnly(key)} color="inherit">
                                                        {typesStr[key] || 'Inconnu'}
                                                    </Link>
                                                )}
                                            />
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    color="primary"
                                                    onChange={typesList.handleSelect(key)}
                                                    checked={typesList.isSelected(key)}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )) }
                                </List>
                            </>
                        )}
                        { Object.keys(properties).length > 0 && (
                            <>
                                <Typography className={classes.titleWithAction}>
                                    Copropriétés
                                    { propertiesList.allSelected() && (
                                        <Button
                                            onClick={propertiesList.selectNone}
                                            color="primary"
                                            className={classes.inlineButton}
                                        >
                                            Cacher tout
                                        </Button>
                                    )}
                                    { !propertiesList.allSelected() && (
                                        <Button
                                            onClick={propertiesList.selectAll}
                                            color="primary"
                                            className={classes.inlineButton}
                                        >
                                            Voir tout
                                        </Button>
                                    )}
                                </Typography>
                                <List dense>
                                    { Object.keys(properties).map((key: string) => (
                                        <ListItem alignItems="flex-start" key={key}>
                                            <ListItemText
                                                primary={(
                                                    <Link href="#" onClick={selectOnlyProperty(key)} color="inherit">
                                                        {properties[key].label}
                                                    </Link>
                                                )}
                                            />
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    color="primary"
                                                    onChange={propertiesList.handleSelect(key)}
                                                    checked={propertiesList.isSelected(key)}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )) }
                                </List>
                            </>
                        )}
                        { Object.keys(dependentContracts).length > 0 && (
                            <>
                                <Typography className={classes.titleWithAction}>
                                    Contrats
                                    { contractsList.allSelected() && (
                                        <Button
                                            onClick={contractsList.selectNone}
                                            color="primary"
                                            className={classes.inlineButton}
                                        >
                                            Cacher tout
                                        </Button>
                                    )}
                                    { !contractsList.allSelected() && (
                                        <Button
                                            onClick={contractsList.selectAll}
                                            color="primary"
                                            className={classes.inlineButton}
                                        >
                                            Voir tout
                                        </Button>
                                    )}
                                </Typography>
                                <List dense>
                                    { Object.keys(dependentContracts).map((key: string) => (
                                        <ListItem alignItems="flex-start" key={key}>
                                            <ListItemText
                                                primary={(
                                                    <Link href="#" onClick={selectOnlyContract(key)} color="inherit">
                                                        {contracts[key].code}
                                                    </Link>
                                                )}
                                            />
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    color="primary"
                                                    onChange={contractsList.handleSelect(key)}
                                                    checked={contractsList.isSelected(key)}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )) }
                                </List>
                            </>
                        )}
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <div className={classes.search}>
                        <TextField
                            id="input-with-icon-textfield"
                            placeholder="Rechercher"
                            className="search-bar"
                            fullWidth
                            onChange={(e) => handleSearch(e.target.value)}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <List>
                        { propertiesKeys.length === 0 && contractsKeys.length === 0 && (
                            <ListItemText className={classes.empty}>
                                Votre syndic vous souhaite la bienvenue sur Cozimmo.
                                <br/>
                                Pour commencer, sélectionnez la copropriété ou le contrat dont vous souhaitez voir les données.
                            </ListItemText>
                        )}

                        { propertiesKeys.map((key: string) => (
                            <Property
                                property={properties[key]}
                                dense={dense}
                                key={key}
                            >
                                <FilesList
                                    group={groupFiles}
                                    files={filesForProperty[key] || []}
                                    filter={(f: File): boolean => typesList.isSelected(f.type)}
                                />
                            </Property>
                        )) }

                        { contractsKeys.map((key: string) => (
                            <Contract
                                contract={contracts[key]}
                                dense={dense}
                                key={key}
                            >
                                <FilesList
                                    group={groupFiles}
                                    files={filesForContract[key] || []}
                                    filter={(f: File): boolean => typesList.isSelected(f.type)}
                                />
                            </Contract>
                        )) }
                    </List>
                </Grid>
            </Grid>
        </div>
    );
};


export default DocumentsPage;
