import { useDispatch, useSelector } from 'react-redux';
import { Store, FlashContent } from '../types';

interface Flash {
    success(msg: string): void;
    error(msg: string): void;
    close(): void;
}

const useFlash = (): Flash => {
    const flash = useSelector((state: Store): FlashContent => state.flash);
    const dispatch = useDispatch();

    const success = (msg: string): void => {
        dispatch({
            type: 'flash',
            data: {
                text: msg,
                variant: 'success',
            },
        });
    };

    const error = (msg: string): void => {
        dispatch({
            type: 'flash',
            data: {
                text: msg,
                variant: 'error',
            },
        });
    };

    const close = (): void => {
        dispatch({
            type: 'flash',
            data: {
                text: '',
                variant: flash.variant,
            },
        });
    };

    return { success, error, close };
};

export default useFlash;
