import * as React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { DialogActions, InputAdornment } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import useStyles from '../hooks/useStyles';
import useForm from '../hooks/useForm';

interface Props {
    open: boolean;
    onClose: () => void;
    onSubmit: (file, type, name) => void;
    types: { [key: string]: string };
}

const NewFileDialog = (props: Props): JSX.Element => {
    const classes = useStyles({});
    const {
        open, onClose, onSubmit, types,
    } = props;
    const [file, setFile] = React.useState({ name: '' });

    const submitHandler = ({ type, name }, reset) => {
        onSubmit(file, type, name);
        reset();
        onClose();
    };

    const { handleSubmit, handleInputChange, inputs, set } = useForm(submitHandler, {
        type: 'generic',
        name: '',
    });

    const onFileChange = (e) => {
        const f = e.currentTarget.files[0];
        setFile(f);
        if (inputs.name === '' && f) {
            set('name', f.name);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} classes={{ paper: classes.settingsDialog }}>
            <DialogTitle disableTypography>
                <Typography variant="h6">
                    Nouveau fichier
                </Typography>

                <Tooltip title="Close">
                    <IconButton className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </DialogTitle>


            <DialogContent>
                <DialogContentText>
                    Sélectionner un fichier à importer.
                </DialogContentText>
                <input
                    accept="image/*"
                    id="raised-button-file"
                    multiple
                    type="file"
                    style={{ display: 'none' }}
                    onChange={onFileChange}
                />
                <TextField
                    margin="dense"
                    label="Fichier"
                    fullWidth
                    variant="filled"
                    value={file.name}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <label htmlFor="raised-button-file">
                                    Choisir
                                </label>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Nom"
                    fullWidth
                    variant="filled"
                    onChange={handleInputChange}
                    value={inputs.name}
                />
                <FormControl variant="filled" fullWidth margin="dense">
                    <InputLabel id="demo-simple-select-filled-label">Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        onChange={handleInputChange}
                        value={inputs.type}
                        name="type"
                    >
                        {Object.keys(types).map((key: string): JSX.Element => (
                            <MenuItem key={key} value={key}>{types[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Créer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewFileDialog;
