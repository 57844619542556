import * as React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import List from '@material-ui/core/List';

import nl2br from './nl2br';
import { Property as PropertyType, Store, User } from '../types';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
    },
    title: {
        padding: theme.spacing(1, 0, 0, 2),
    },
    panel: {
        margin: theme.spacing(0, 2, 4, 2),
    },
    files: {
        margin: theme.spacing(0, 2),
    },
    fakeListItem: {
        display: 'block',
        marginLeft: theme.spacing(1),
    },
}));

interface Props {
    property: PropertyType;
    dense: boolean;
}

const Property = (props: React.PropsWithChildren<Props>): JSX.Element => {
    const classes = useStyles({});
    const { scope } = useSelector((state: Store): User => state.me);
    const { property, dense, children } = props;

    return (
        <div className={classes.root}>
            <Typography component="h2" variant="h6" className={classes.title}>
                Copropriété&nbsp;:&nbsp;
                {property.code}
                {' '}
                {property.label}
            </Typography>

            {!dense && (
                <>
                    <List>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary="Adresse"
                                secondary={nl2br(property.address.address)}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={scope === 'syndic' ? 'Société de syndic' : 'Gestionnaire'}
                                secondary={(
                                    <>
                                        {property.properties_agent.label}
                                        <br />
                                        {nl2br(property.properties_agent.address.address)}
                                    </>
                                )}
                            />
                        </ListItem>
                        {property.unions_representatives.length > 0 && (
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary="Conseillers syndicaux"
                                    secondary={property.unions_representatives.map((person) => (
                                        <span className={classes.fakeListItem} key={person.email}>
                                            {` • ${person.firstname} ${person.lastname} <${person.email}>`}
                                        </span>
                                    ))}
                                />
                            </ListItem>
                        )}
                    </List>

                    <div className={classes.panel}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>
                                    Clés de répartition de charges
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Clé</TableCell>
                                            <TableCell align="left">Désignation</TableCell>
                                            <TableCell align="right">Tantièmes</TableCell>
                                            <TableCell align="right">Tantièmes répartis</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {property.proportions.map((row) => (
                                            <TableRow key={row.code}>
                                                <TableCell component="th" scope="row">
                                                    {row.code}
                                                </TableCell>
                                                <TableCell align="left">{row.label}</TableCell>
                                                <TableCell align="right">{row.base}</TableCell>
                                                <TableCell align="right">{row.repartition}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                </>
            )}

            <div className={classes.files}>
                {!dense && (
                    <Typography>
                        Documents de copropriété
                    </Typography>
                )}

                {children}
            </div>
        </div>
    );
};

export default Property;
