import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Help from '@material-ui/icons/Help';
import { Button } from '@material-ui/core';

import useTheme from '../hooks/useTheme';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        maxHeight: '50px',
        marginRight: theme.spacing(2),
    },
    navbar: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

interface Props {
    onOpenSettingsDialog: (e: React.MouseEvent<HTMLElement>) => void;
    onHelp?: (e: React.MouseEvent<HTMLElement>) => void;
}

const NavBar = (props: Props) => {
    const theme = useTheme({});
    const classes = useStyles({});

    const { onOpenSettingsDialog, onHelp } = props;

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.navbar}>
                <Toolbar>
                    <img src={theme.logo} alt="logo" className={classes.logo} />
                    <div className={classes.grow} />
                    <Button
                        className="settings-button"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls="settings"
                        aria-haspopup="true"
                        onClick={onOpenSettingsDialog}
                        color="inherit"
                        endIcon={<AccountCircle />}
                    >
                        Mon compte
                    </Button>
                    { onHelp !== null && (
                        <Button
                            className="help-button"
                            edge="end"
                            onClick={onHelp}
                            color="inherit"
                            endIcon={<Help />}
                        >
                            Aide
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default NavBar;
