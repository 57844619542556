export const types = {
    generic: 'Autres',
    facture: 'Factures',
    ag: 'Assemblées générales',
    appel_fonds: 'Appels de fonds',
    reglement_copro: 'Règlement de copropriété',
    sru: 'SRU',
    extrait_compte: 'Extraits de compte',
    Contrats: 'Contrats',
    Courriers: 'Courriers',
    carnets_entretien: 'Carnets d’entretien',
    fiches_synthetique: 'Fiches synthétique',
    diagnostics_technique: 'Diagnostiques technique',
    documents_administratif: 'Documents administratif',
    documents_comptable: 'Documents comptable',
};

// TODO subset of types
export const contractTypes = {
    generic: 'Autres',
    facture: 'Factures',
    ag: 'Assemblées générales',
    appel_fonds: 'Appels de fonds',
    sru: 'SRU',
    extrait_compte: 'Extraits de compte',
    Contrats: 'Contrats',
    Courriers: 'Courriers',
    carnets_entretien: 'Carnets d’entretien',
    fiches_synthetique: 'Fiches synthétique',
    documents_administratif: 'Documents administratif',
    documents_comptable: 'Documents comptable',
};

// TODO subset of types
export const propertyTypes = {
    generic: 'Autres',
    facture: 'Factures',
    ag: 'Assemblées générales',
    appel_fonds: 'Appels de fonds',
    reglement_copro: 'Règlement de copropriété',
    sru: 'SRU',
    extrait_compte: 'Extraits de compte',
    Contrats: 'Contrats',
    Courriers: 'Courriers',
    carnets_entretien: 'Carnets d’entretien',
    fiches_synthetique: 'Fiches synthétique',
    diagnostics_technique: 'Diagnostiques technique',
};
