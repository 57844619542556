import * as React from 'react';
import { useParams } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';

import Contract from './Contract';
import FilesList from './FilesList';
import { File } from '../types';
import { useClient } from '../ClientProvider';
import withConfirm from '../hooks/withConfirm';
import NewFileDialog from './NewFileDialog';
import { contractTypes as types } from '../files';
import useFlash from '../hooks/useFlash';

const ContractPage = ({ confirm }): JSX.Element => {
    const { guid } = useParams();
    const client = useClient();
    const [contract, setContract] = React.useState(null);
    const [files, setFiles] = React.useState([]);
    const { success, error } = useFlash();

    React.useEffect(() => {
        client.getContract(guid).then((response) => {
            setContract(response.data);
        });
    }, []);

    React.useEffect(() => {
        if (!contract) {
            return;
        }
        client.getContractFiles(contract).then((response) => {
            setFiles(response.data);
        });
    }, [contract]);

    const handleDeleteFile = (file: File) => () => {
        client.deleteFile(file).then(() => client.getContractFiles(contract)).then((response) => {
            setFiles(response.data);
        });
    };

    const [openSettings, setOpenSettings] = React.useState(false);

    const onFileSubmit = (file, type, name) => {
        client.uploadContractFile(contract, file, type, name).then(() => {
            success('Fichier ajouté avec succès.');
            client.getContractFiles(contract).then((response) => {
                setFiles(response.data);
            });
        }).catch(() => {
            error('Une erreur est survenue durant l\'ajout du fichier.');
        });
    };

    return (
        <div>
            { contract && (
                <Contract contract={contract} dense={false}>
                    <>
                        <Button
                            onClick={(): void => setOpenSettings(true)}
                            variant="contained"
                            color="primary"
                        >
                            Ajouter
                        </Button>
                        <FilesList
                            group
                            files={files}
                            filter={(f: File): boolean => true}
                            actions={(file: File): JSX.Element => (
                                <Tooltip title="Supprimer le fichier">
                                    <IconButton edge="end" aria-label="comments" onClick={confirm(handleDeleteFile(file))}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        />
                        <NewFileDialog
                            open={openSettings}
                            onClose={(): void => setOpenSettings(false)}
                            onSubmit={onFileSubmit}
                            types={types}
                        />
                    </>
                </Contract>
            )}
        </div>
    );
};

export default withConfirm(ContractPage);
