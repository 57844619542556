const debounce = (func, wait, immediate) => {
    let timeout;

    return (...args): void => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;

        const later = (): void => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
};

export default debounce;
