import { useDispatch } from 'react-redux';

interface Actions {
    setUser(data): void;
    setToken(token: string): void;
    logout(): void;
    setUsername(username: string): void;
}

const useActions = (): Actions => {
    const dispatch = useDispatch();

    const logout = (): void => {
        dispatch({ type: 'logout' });
    };

    const setUser = (data): void => {
        dispatch({ type: 'user', data });
    };

    const setToken = (token: string): void => {
        dispatch({ type: 'user', token });
    };

    const setUsername = (username: string): void => {
        dispatch({ type: 'username', username });
    };

    return {
        setUser,
        setToken,
        logout,
        setUsername,
    };
};

export default useActions;
