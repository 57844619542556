import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useSelector } from 'react-redux';
import { useClient } from '../ClientProvider';
import useStyles from '../hooks/useStyles';
import useForm from '../hooks/useForm';
import RouterLink from './RouterLink';
import useFlash from '../hooks/useFlash';
import { Store } from '../types';

const Remind = () => {
    const classes = useStyles({});
    const client = useClient();
    const history = useHistory();
    const { success } = useFlash();
    const username = useSelector((state: Store): string => state.username);

    const submitHandler = ({ username }, reset): void => {
        client.remind(username).then(() => {
            success('Vous allez recevoir un email avec toutes les informations nécessaires pour réinitialiser votre mot de passe.');
            history.push('/login');
            reset();
        });
    };

    const { handleSubmit, handleInputChange, inputs } = useForm(submitHandler, {
        username,
    });

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit} method="POST">
            <Typography component="p" variant="body2">
                Renseignez votre nom d'utilisateur. Vous recevrez un email avec la procédure à suivre pour changer votre mot de passe.
            </Typography>
            <Typography component="p" variant="body2">
                <strong>Attention à bien utiliser votre nom d'utilisateur et non votre email.</strong>
            </Typography>
            <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Identifiant"
                name="username"
                autoFocus
                onChange={handleInputChange}
                value={inputs.username}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Réinitialiser
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link component={RouterLink} to="/login" variant="body2">
                        Se connecter ?
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
};

export default Remind;
