import * as React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PDFIcon from '@material-ui/icons/PictureAsPdf';
import FileIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import SpreadsheetIcon from '@material-ui/icons/Assessment';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';

import { File } from '../types';
import { bytes } from '../filters';
import { useClient } from '../ClientProvider';
import presentFile from '../presentFile';

const icon = (f: File): JSX.Element => {
    const ext = f.name.split('.').pop() || '';
    switch (ext.toLowerCase()) {
    case 'pdf':
        return <PDFIcon />;
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
        return <ImageIcon />;
    case 'xls':
    case 'csv':
    case 'xlsx':
        return <SpreadsheetIcon />;
    default:
    }
    return <FileIcon />;
};

interface Props {
    file: File;
    withDivider: boolean;
    actions?: (file: File) => JSX.Element;
}

const FileItem = (props: Props): JSX.Element => {
    const { file, withDivider, actions } = props;
    const client = useClient();

    const handleDownload = (): void => {
        client.getDownloadURL(file)
            .then((response): void => {
                if (response.data.url) {
                    // eslint-disable-next-line no-restricted-globals
                    location.href = response.data.url;
                }
            });
    };

    const presentable = presentFile(file);

    return (
        <>
            <ListItem alignItems="flex-start">
                <Hidden xsDown>
                    <ListItemIcon>
                        {icon(file)}
                    </ListItemIcon>
                </Hidden>
                <ListItemText
                    primary={presentable.primary}
                    secondary={presentable.secondary}
                />
                <ListItemSecondaryAction>
                    {actions && actions(file)}
                    <Tooltip title={`Télécharger ${presentable.name} (${bytes(presentable.size)})`}>
                        <IconButton edge="end" aria-label="comments" onClick={handleDownload}>
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
            {withDivider && <Divider />}
        </>
    );
};

export default FileItem;
