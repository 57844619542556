import * as React from 'react';

interface CheckboxList {
    current: { [key: string]: boolean };
    selectedCount(): number;
    isSelected(key: string): boolean;
    allSelected(): boolean;
    handleSelect(key: string): (e: React.ChangeEvent<HTMLInputElement>) => void;
    selectOnly(key: string): void;
    selectAll(): void;
    selectNone(): void;
    update(items: { [key: string]: any }): void;
}

const useCheckboxList = (items: { [key: string]: any }): CheckboxList => {
    const [current, set] = React.useState((): { [key: string]: boolean } => {
        const s = {};
        Object.keys(items).forEach((k: string): void => { s[k] = true; });
        return s;
    });

    const isSelected = (key: string): boolean => current[key] === true;

    const selectedCount = (): number => {
        let count = 0;

        Object.keys(current).forEach((k: string): void => {
            if (current[k]) {
                count++;
            }
        });

        return count;
    };

    const allSelected = (): boolean => {
        if (Object.keys(items).length === 0) {
            return false;
        }

        return Object.keys(items).filter((k: string): boolean => current[k] !== true).length === 0;
    };

    const selectAll = (): void => {
        const newSelected = {};
        Object.keys(items).forEach((k: string): void => {
            newSelected[k] = true;
        });
        set(newSelected);
    };

    const selectOnly = (key: string): void => set({ [key]: true });

    // eslint-disable-next-line max-len
    const handleSelect = (key: string): ((e: React.ChangeEvent<HTMLInputElement>) => void) => (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (!e) {
            return;
        }

        set({ ...current, [key]: e.target.checked });
    };

    const selectNone = (): void => set({});

    // eslint-disable-next-line no-shadow
    const update = (items: { [key: string]: any }): void => {
        const s = {};
        Object.keys(items).forEach((k: string): void => { s[k] = true; });
        set(s);
    };

    return {
        current,
        isSelected,
        selectedCount,
        allSelected,
        handleSelect,
        selectOnly,
        selectAll,
        selectNone,
        update,
    };
};

export default useCheckboxList;
