import { makeStyles } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        maxWidth: '100px',
    },
    root: {
        width: '85%',
        margin: 'auto',
        borderRadius: theme.shape.borderRadius,
    },
    controls: {
        padding: theme.spacing(2),
    },
    empty: {
        color: 'gray',
        textAlign: 'center',
    },
    inline: {
        display: 'inline',
    },
    group: {
        padding: '20px 0 0 15px',
    },
    main: {
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: theme.spacing(10),
    },
    mainWithSidebar: {
        paddingLeft: `calc(${theme.spacing(2)}px + 240px)`,
        paddingRight: theme.spacing(2),
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    tabs: {
        display: 'initial',
    },

    feedbackBtn: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },

    search: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

    sidebar: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
    },

    titleWithAction: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 2, 0, 2),
    },

    inlineButton: {
        padding: '0',
    },

    settingsDialog: {
        minWidth: '480px',
    },
}));

export default useStyles;
