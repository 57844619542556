import * as React from 'react';
import { useSelector } from 'react-redux';
import Joyride, {
    ACTIONS, CallBackProps, EVENTS, STATUS,
} from 'react-joyride';
import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Fab from '@material-ui/core/Fab';

import SettingsDialog from './SettingsDialog';
import FeedbackDialog from './FeedbackDialog';
import useStyles from '../hooks/useStyles';
import Copyright from './Copyright';
import NavBar from './NavBar';
import useFlash from '../hooks/useFlash';
import SnackbarContentWrapper from './SnackbarContentWrapper';
import {FlashContent, Store, User} from '../types';
import useTheme from '../hooks/useTheme';

const steps = [
    {
        content: <p>Bienvenue sur Cozimmo.<br/><br/>Prenons quelques minutes pour faire le tour du propriétaire.</p>,
        placement: 'center',
        target: 'body',
    },
    {
        content: <p>Via ce paneau, vous pouvez sélectionner contrats et copropriétés qui vous intéressent.</p>,
        placement: 'right',
        target: '.filter-panel',
    },
    {
        content: <p>Une fois que vous avez fait votre sélection, vous pouvez rechercher un fichier en particulier en utilisant la barre de recherche.</p>,
        placement: 'right',
        target: '.search-bar',
    },
    {
        content: <p>Vos données personnelles sont disponibles via "Mon Compte".<br/>Pour continuer, cliquez sur le bouton.</p>,
        placement: 'right',
        target: '.settings-button',
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        spotlightClicks: true,
    },
    {
        content: <p>Le premier paneau liste les détails de votre compte. Vous pouvez modifier votre adresse email ici.</p>,
        placement: 'right',
        target: '.account-tab',
        styles: {
            options: {
                zIndex: 10000,
            },
        },
    },
    {
        content: <p>Le paneau "Sécurité" vous permet de modifier votre mot de passe. Pour votre sécurité, vous devriez le faire dès votre première connexion.</p>,
        placement: 'right',
        target: '.security-tab',
        styles: {
            options: {
                zIndex: 10000,
            },
        },
    },
    {
        content: <p>Ce paneau vous permet de regrouper plusieurs comptes utilisateurs et ainsi accéder à toutes les données des comptes depuis un seul. Il vous sera demandé les identifiants des comptes à connecter.</p>,
        placement: 'right',
        target: '.links-tab',
        styles: {
            options: {
                zIndex: 10000,
            },
        },
    },
    {
        content: <p>Si vous avez besoin d'aide, vous pouvez nous contacter via le formulaire de contact. <br/>Ceci conclut la visite guidée, nous vous souhaitons une agréable expérience.</p>,
        placement: 'right',
        target: '.contact-button',
    },
];

interface Props {
    sidebar?: JSX.Element;
}

const Main = (props: React.PropsWithChildren<Props>): JSX.Element => {
    const classes = useStyles({});
    const flash = useSelector((state: Store): FlashContent => state.flash);
    const { close } = useFlash();
    const theme = useTheme();

    const { sidebar, children } = props;

    const handleClose = (event, reason): void => {
        if (reason === 'clickaway') {
            return;
        }

        close();
    };

    const user = useSelector((state: Store): User => state.me);
    const [openSettings, setOpenSettings] = React.useState(false);
    const [openFeedback, setOpenFeedback] = React.useState(false);
    const [run, setRun] = React.useState(false);
    const [stepIndex, setStepIndex] = React.useState(0);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [onboardingDone, setOnboardingDone] = React.useState(false);

    React.useEffect(() => {
        setRun(user.loginHistory.length === 1);
    } , [user]);

    const handleJoyrideCallback = (data: CallBackProps) => {
        const {
            action, index, type, status,
        } = data;

        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
            setRun(false);
            setStepIndex(0);
            setOnboardingDone(true);
        } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
            const newStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

            if (!openSettings && index === 3) {
                setRun(false);
            } else if (openSettings && index === 3) {
                setRun(false);
                setStepIndex(newStepIndex);
                setTimeout(() => {
                    setRun(true);
                }, 400);
            } else if (openSettings && index === 4) {
                setTabIndex(1);
                setStepIndex(newStepIndex);
            } else if (openSettings && index === 5) {
                setTabIndex(2);
                setStepIndex(newStepIndex);
            } else if (openSettings && index === 6) {
                setOpenSettings(false);
                setStepIndex(newStepIndex);
            } else {
                // Update state to advance the tour
                setStepIndex(newStepIndex);
            }
        }

        // tslint:disable:no-console
        // console.groupCollapsed(type === EVENTS.TOUR_STATUS ? `${type}:${status}` : type);
        // console.log(data);
        // console.groupEnd();
        // tslint:enable:no-console
    };

    const handleOpenSettings = (): void => {
        setOpenSettings(true);
        setTabIndex(0);
        setStepIndex(stepIndex === 3 ? 4 : stepIndex);
        setRun(stepIndex !== 0);
    };

    const handleHelp = (): void => {
        setTabIndex(0);
        setStepIndex(0);
        setOnboardingDone(false);
        setOpenSettings(false);
        setRun(true);
    };

    return (
        <>
            <NavBar onOpenSettingsDialog={handleOpenSettings} onHelp={handleHelp} />

            { sidebar }

            <Container component="main" maxWidth={false} className={clsx({ [classes.main]: true, [classes.mainWithSidebar]: !!sidebar })}>
                {children}
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>

            <SettingsDialog open={openSettings} onClose={(): void => setOpenSettings(false)} tabIndex={tabIndex} />
            <FeedbackDialog open={openFeedback} onClose={(): void => setOpenFeedback(false)} />

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={!!flash.text}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <SnackbarContentWrapper
                    onClose={handleClose}
                    variant={flash.variant}
                    message={flash.text}
                />
            </Snackbar>

            <Fab
                aria-label="help"
                color="primary"
                className={`${classes.feedbackBtn} contact-button`}
                onClick={(): void => setOpenFeedback(true)}
            >
                <FeedbackIcon />
            </Fab>
            <Joyride
                steps={steps}
                continuous
                run={run && !onboardingDone}
                stepIndex={stepIndex}
                showSkipButton
                hideBackButton
                callback={handleJoyrideCallback}
                styles={{
                    options: {
                        primaryColor: theme.primaryColor,
                    },
                }}
                locale={{
                    back: 'Précédent',
                    close: 'Fermer',
                    last: 'Fin',
                    next: 'Suivant',
                    open: 'Ouvrir',
                    skip: 'Passer',
                }}
            />
        </>
    );
};

export default Main;
