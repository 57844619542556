import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { useClient } from '../ClientProvider';
import useStyles from '../hooks/useStyles';
import useForm from '../hooks/useForm';
import useFlash from '../hooks/useFlash';

const Reset = () => {
    const classes = useStyles({});
    const client = useClient();
    const history = useHistory();
    const { username, token } = useParams();
    const { success } = useFlash();

    const submitHandler = ({ password, passwordConfirmation }, reset): void => {
        client.reset(username, token, password, passwordConfirmation).then(() => {
            success('Mot de passe modifié avec succès.');
            history.push('/login');
            reset();
        });
    };

    const { handleSubmit, handleInputChange, inputs } = useForm(submitHandler, {
        password: '',
        passwordConfirmation: '',
    });

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit} method="POST">
            <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Mot de passe"
                name="password"
                type="password"
                autoFocus
                onChange={handleInputChange}
                value={inputs.password}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="passwordConfirmation"
                label="Confirmer mot de passe"
                type="password"
                id="password_confirmation"
                onChange={handleInputChange}
                value={inputs.passwordConfirmation}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Modifier
            </Button>
        </form>
    );
};

export default Reset;
