// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import moment from 'moment';
import 'moment-timezone';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { DialogContent, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EditIcon from '@material-ui/icons/Edit';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import EmailIcon from '@material-ui/icons/Email';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

import { useClient } from '../ClientProvider';
import { User, Store } from '../types';
import useFlash from '../hooks/useFlash';
import useActions from '../hooks/useActions';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingTop: theme.spacing(2),
    },

    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),

        minHeight: 'initial',
    },
}));

const AccountTab = (): JSX.Element => {
    const classes = useStyles({});
    const client = useClient();
    const history = useHistory();
    const user = useSelector((state: Store): User => state.me);
    const [showingField, showField] = React.useState('');
    const [email, setEmail] = React.useState(user.email);
    const [notifications, setNotifications] = React.useState(user.updateNotifications);
    const [performingAction, setPerformingAction] = React.useState(false);
    const { success, error } = useFlash();
    const actions = useActions();

    const onLogout = () => {
        client.clearToken().then((reload: boolean): void => {
            if (reload) {
                // TODO(kevin.lebrun): redirect to /admin/users?q=xxx
                window.location.reload();
            } else {
                // FIXME clear current user to fix theme
                history.push('/');
            }
        });
    };

    const hideFields = () => {
        showField('');
    };

    let lastSigninMoment = null;
    if (user.loginHistory.length > 1) {
        const d = user.loginHistory[1];
        lastSigninMoment = moment.tz(d.date, d.timezone);
    }
    const changeEmailAddress = () => {
        if (email === user.email) {
            return;
        }
        setPerformingAction(true);

        client.updateUserInfo(user, email, user.updateNotifications).then(() => {
            hideFields();
            success('Email mis à jour avec succès.');
            return client.getCurrentUser();
        }).then((response) => {
            actions.setUser(response.data);
        }).catch((reason) => {
            console.log(reason);
            error('Une erreur est survenue.');
        })
            .finally(() => {
                setPerformingAction(false);
            });
    };

    const changeField = (fieldId) => {
        switch (fieldId) {
        case 'email-address':
            changeEmailAddress();
            break;

        default:
            break;
        }
    };

    const handleKeyDown = (event, fieldId) => {
        if (!event || !fieldId) {
            return;
        }

        if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
            return;
        }

        const { key } = event;

        if (!key) {
            return;
        }

        if (key === 'Escape') {
            hideFields();
        } else if (key === 'Enter') {
            changeField(fieldId);
        }
    };

    const handleEmailChange = (event) => {
        if (!event) {
            return;
        }

        setEmail(event.target.value);
    };

    const handleNotificationsChange = (event) => {
        if (!event) {
            return;
        }

        setNotifications(event.target.checked);

        setPerformingAction(true);

        client.updateUserInfo(user, user.email, event.target.checked).then(() => {
            hideFields();
            success('Préférence mise à jour avec succès.');
            return client.getCurrentUser();
        }).then((response) => {
            actions.setUser(response.data);
        }).catch((reason) => {
            console.log(reason);
            error('Une erreur est survenue.');
            // const { code } = reason;
            // const { message } = reason;
            //
            // switch (code) {
            // default:
            //     this.props.openSnackbar(message);
            // }
        })
            .finally(() => {
                setPerformingAction(false);
            });
    };

    return (
        <DialogContent classes={{ root: classes.dialogContent }}>
            <List disablePadding>
                <ListItem>
                    <Hidden xsDown>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                    </Hidden>

                    <ListItemText
                        primary="Nom"
                        secondary={`${user.firstname} ${user.lastname}`}
                    />
                </ListItem>

                <ListItem>
                    <Hidden xsDown>
                        <ListItemIcon>
                            <PersonOutlineIcon />
                        </ListItemIcon>
                    </Hidden>

                    <ListItemText
                        primary="Identifiant"
                        secondary={user.username}
                    />
                </ListItem>

                <ListItem>
                    <Hidden xsDown>
                        <ListItemIcon>
                            <EmailIcon />
                        </ListItemIcon>
                    </Hidden>

                    {showingField === 'email-address'
                    && (
                        <TextField
                            autoComplete="email"
                            autoFocus
                            disabled={performingAction}
                            fullWidth
                            label="Email"
                            placeholder={user.email}
                            required
                            type="email"
                            value={email}
                            variant="filled"

                            onBlur={hideFields}
                            onKeyDown={(event) => handleKeyDown(event, 'email-address')}

                            onChange={handleEmailChange}
                        />
                    )}

                    {showingField !== 'email-address'
                    && (
                        <>
                            <ListItemText
                                primary="Email"
                                secondary={user.email}
                            />

                            <ListItemSecondaryAction>
                                {user.email
                            && (
                                <Tooltip title="Change">
                                    <div>
                                        <IconButton
                                            disabled={performingAction}
                                            onClick={() => showField('email-address')}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            )}
                            </ListItemSecondaryAction>
                        </>
                    )}
                </ListItem>

                <ListItem>
                    <Hidden xsDown>
                        <ListItemIcon>
                            <NotificationsIcon />
                        </ListItemIcon>
                    </Hidden>

                    <ListItemText
                        primary="Notifications"
                        secondary="Recevoir emails de mises à jour"
                    />
                    <ListItemSecondaryAction>
                        <Switch
                            edge="end"
                            onChange={handleNotificationsChange}
                            checked={notifications}
                            disabled={performingAction}
                            inputProps={{ 'aria-labelledby': 'switch-list-label-notifications' }}
                            color="primary"
                        />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <AccessTimeIcon />
                    </ListItemIcon>

                    {lastSigninMoment && (
                        <ListItemText
                            primary="Dernière connexion"
                            secondary={lastSigninMoment.calendar()}
                        />
                    )}
                    {!lastSigninMoment && (
                        <ListItemText
                            primary="Dernière connexion"
                            secondary="Aucune"
                        />
                    )}

                    <ListItemSecondaryAction>
                        <Button color="primary" variant="contained" onClick={onLogout}>Se déconnecter</Button>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </DialogContent>
    );
};

export default AccountTab;
