import * as React from 'react';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles, Typography } from '@material-ui/core';

import { types } from '../files';
import FileItem from './FileItem';
import { File } from '../types';

const useStyles = makeStyles((theme) => ({
    noFiles: {
        color: theme.palette.text.secondary,
        padding: theme.spacing(0, 0, 2, 0),
    },
    subheader: {
        lineHeight: 'inherit',
    },
}));

const groupBy = (files: File[]): { [key: string]: File[] } => (
    files.reduce((acc, file: File) => {
        const key = file.type;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(file);
        return acc;
    }, {})
);

interface Props {
    group: boolean;
    files: File[];
    filter: (file: File) => boolean;
    actions?: (file: File) => JSX.Element;
}

const FilesList = (props: Props) => {
    const classes = useStyles({});
    const {
        group, files, filter, actions,
    } = props;

    const filtered = files.filter(filter);
    const grouped = groupBy(filtered);

    return (
        <>
            { !group && (
                <List>
                    { filtered.map((f: File, i: number, fs: File[]) => (
                        <FileItem file={f} withDivider={i !== fs.length - 1} key={f.guid} actions={actions} />
                    )) }
                </List>
            )}

            { group && (
                <List>
                    { Object.keys(grouped).map((type: string) => (
                        <React.Fragment key={type}>
                            <ListSubheader disableSticky className={classes.subheader}>{types[type]}</ListSubheader>
                            { grouped[type].map((f: File, i: number, fs: File[]) => (
                                <FileItem file={f} withDivider={i !== fs.length - 1} key={f.guid} actions={actions} />
                            )) }
                        </React.Fragment>
                    ))}
                </List>
            )}

            { filtered.length === 0 && (
                <Typography variant="body2" className={classes.noFiles}>
                    Aucun document pour le moment.
                </Typography>
            )}
        </>
    );
};

export default FilesList;
